<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="票种名称">
            <el-input v-model="state.queryForm.ticketName" class="mr-10" placeholder="请输入票种名称" />
          </el-form-item>

          <el-form-item label="票种类型">
            <el-select v-model="state.queryForm.ticketType" class="m-2" placeholder="请选择票种类型" @change="handleChangeSelect">
              <el-option v-for="item in state.typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column align="center" label="唯一标识" prop="ticketCode" show-overflow-tooltip />
      <el-table-column align="center" label="票种名称" prop="ticketName" show-overflow-tooltip />

      <!--  票类型 展会EXHIBITION 论坛FORUM -->
      <el-table-column align="center" label="票种类型" prop="ticketType" show-overflow-tooltip>
        <template #default="{ row }">
          {{ filterTicketsRelation[row.ticketType] }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="票种关联" prop="meetingName" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.meetingName ? row.meetingName : '暂无关联' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="票种出售时间" prop="buyStartTime" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.buyStartTime }}</div>
          <div>{{ row.buyEndTime }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="票种入场时间" prop="entranceStartTime" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.entranceStartTime }}</div>
          <div>{{ row.entranceEndTime }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remarkPc" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.remarkPc ? row.remarkPc : '暂无备注' }}</div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="门票订单数量" prop="ticketOrderCount" show-overflow-tooltip />
      <el-table-column align="center" label="订单总金额" prop="ticketOrderTotalPrice" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            {{
              row.ticketOrderTotalPrice
                ? row.ticketOrderTotalPrice.toFixed(2) + '元'
                : 0
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="门票总人数" prop="userTicketCount" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            {{ row.userTicketCount ? row.userTicketCount + '人' : '暂无' }}
          </div>
        </template>
      </el-table-column>
      <!-- 待上架 0 已上架 1 下架 2 -->
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-tag v-if="row.status == 1" type="success">
            {{ filterShelfStatus[row.status] }}
          </el-tag>
          <el-tag v-if="row.status == 0" type="warning">
            {{ filterShelfStatus[row.status] }}
          </el-tag>
          <el-tag v-if="row.status == 2" type="danger">
            {{ filterShelfStatus[row.status] }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" show-overflow-tooltip width="350">
        <template #default="{ row }">
          <div class="d-flex flex-wrap">
            <el-button type="primary" @click="handleThrow(row)">投放</el-button>
            <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
            <el-button type="primary" @click="handleJumpOrderList(row)">
              订单列表
            </el-button>
            <el-button type="primary" @click="handleJumpTicketsList(row)">
              门票列表
            </el-button>
            <el-button class="mt-10" type="primary" @click="handleJumpCouponCode(row)">
              优惠码设置
            </el-button>
            <el-button class="mt-10" type="primary" @click="handleTicketsShare(row)">
              门票分享
            </el-button>
            <el-button class="mt-10" type="primary" @click="handleMobileReport(row)">
              人工报名
            </el-button>
            <el-button class="mt-10" type="primary" @click="handleDownLoad(row)">
              门票表单下载
            </el-button>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination v-if="state.total > 10" background :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit-tickets ref="refTicketsDialog" @noticeRefresh="getData" />
    <tickets-share-popup ref="refTicketsSharePopup" />
    <mobile-report ref="refMobileReport" @noticeRefresh="getData" />
    <exportRegister ref="exportRegisterRef"></exportRegister>
  </div>
</template>
<script>
import exportRegister from './components/exportRegister.vue'
export default {
  name: 'TicketsManage',
  components: {
    exportRegister,
  },
}
</script>
<script setup>
import { getCurrentInstance, reactive, ref, onActivated } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Search, Eleme } from '@element-plus/icons'
import { ticketList, downLoadTicketForm } from '@/api/exhibitionManage'
import { useComponent } from './components/index'
import { debounce } from '@/utils/debounce'
const router = useRouter() // 获取路由实例
const route = useRoute() // 获取路由对象
const loading = ref(false) // 列表动画加载
const refMobileReport = ref(null) //
// 引入组件
const { editTickets, ticketsSharePopup, mobileReport } = useComponent()

const { proxy } = getCurrentInstance()
const filterShelfStatus = {
  0: '待上架',
  1: '已上架',
  2: '已下架',
}
const filterTicketsRelation = {
  EXHIBITION: '展会类型',
  FORUM: '论坛类型',
  PACKAGE: '套票类型',
  null: '暂无类型',
  HOLD_MEETING: '会议类型',
}
const refTicketsDialog = ref(null) // 编辑弹框
const refTicketsSharePopup = ref(null) // 分享弹框

const exportRegisterRef = ref(null)
const state = reactive({
  queryForm: {
    meetingCode: '',
    pageNum: 1,
    pageSize: 10,
    ticketName: '',
    ticketType: '',
    meetingType: 'EXHIBITION',
  },
  total: 0,
  list: [],
  layout: 'prev, pager, next',
  editRef: null,
  typeOptions: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '展会类型',
      value: 'EXHIBITION',
    },
    {
      label: '论坛类型',
      value: 'FORUM',
    },
    {
      label: '套票类型',
      value: 'PACKAGE',
    },
  ],
})
// 跳转门票投放
const handleThrow = (row) => {
  console.log('投放', row)
  router.push({
    path: '/exhibitionManage/ticketsThrow',
    query: row,
  })
}
// 编辑
const handleEdit = debounce((row) => {
  console.log('编辑', row)
  refTicketsDialog.value.handleOpen({ ...row })
})
// 初始化数据
const getData = async () => {
  loading.value = true
  const { data, total } = await ticketList(state.queryForm)
  console.log(data, '1123123')
  state.list = data.data
  state.total = data.total
  setTimeout(() => {
    loading.value = false
  }, 500)
}
// 下拉事件
const handleChangeSelect = (e) => {
  state.queryForm.ticketType = e
}
// 查询
const handleQueryData = debounce(() => {
  state.queryForm.pageNum = 1
  getData()
})
// 条数
const handleSizeChange = debounce((pageSize) => {
  state.queryForm.pageSize = pageSize
  getData()
})
// 页码
const handleCurrentChange = debounce((pageNum) => {
  state.queryForm.pageNum = pageNum
  getData()
})
// 跳转订单列表
const handleJumpOrderList = (row) => {
  let query = {
    ticketCode: row.ticketCode,
    ticketName: row.ticketName,
  }
  router.push({
    path: '/exhibitionManage/orderList',
    query,
  })
}
// 跳转门票列表
const handleJumpTicketsList = (row) => {
  router.push({
    path: '/exhibitionManage/ticketsList',
    query: {
      ticketCode: row.ticketCode,
    },
  })
}
// 跳转优惠码设置
const handleJumpCouponCode = (row) => {
  let query = {
    ticketCode: row.ticketCode,
  }
  router.push({
    path: '/exhibitionManage/couponCode',
    query,
  })
}
// 门票分享
const handleTicketsShare = (row) => {
  console.log('门票分享', row)
  refTicketsSharePopup.value.handleOpen(row)
}
// 人工报名
const handleMobileReport = (row) => {
  console.log('人工报名', row)
  let obj = {
    ticketCode: row.ticketCode,
  }
  console.log('obj', obj)
  refMobileReport.value.handleOpen(obj)
}

const handleDownLoad = async (row) => {
  // const { data } = await downLoadTicketForm(row.ticketCode)
  // console.log(data, '1123123')
  // window.open(data.fileUrl)
  row.code = row.ticketCode
  row.relationType = 'TICKET'
  exportRegisterRef.value.handleOpen(row, `门票表单下载`, `TICKET_REGISTER_REPORT`)
}
// 激活组件
onActivated(() => {
  console.log('onActivated======')
  state.queryForm.meetingCode = route.query?.meetingCode || ''
  state.queryForm.meetingCode && getData()
})
</script>

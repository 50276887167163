<template>
  <div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="身份名称" prop="name" show-overflow-tooltip />
      <el-table-column label="邀请码配置状态">
        <template #default="{ row }">
          <el-select v-model="row.isOpenInvitationSign" @change="handleChangstatus(row,$event)">
            <el-option label="正常报名" :value="false"></el-option>
            <el-option label="使用邀请码报名" :value="true"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getRegisterConfigList, setUpRegister } from '@/api/channel'

export default defineComponent({
  name: 'sharecodeConfig',
  components: {},
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        meetingCode: '',
      },
      type: '',
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      channelDetailRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
    })

    const queryData = () => {
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getRegisterConfigList(state.queryForm.meetingCode)
      data.forEach((v) => {
        v.isOpenInvitationSign =
          v.isOpenInvitationSign != null ? v.isOpenInvitationSign : false
      })
      state.list = data
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }

    onActivated(() => {
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      queryData()
    })

    const handleChangstatus = async (row, e) => {
      console.log(row, e)
      proxy.$baseConfirm(
        '你确定要修改当前报名方式吗',
        null,
        async () => {
          row.isOpenInvitationSign = e
          const { msg } = await setUpRegister(row)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        },
        async () => {
          row.isOpenInvitationSign = !row.isOpenInvitationSign
        }
      )
    }

    return {
      ...toRefs(state),
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      Delete,
      Search,
      Plus,
      handleChangstatus
    }
  },
})
</script>
<style lang="scss" scoped></style>
